
body {
    margin: 0;
    overflow-x: hidden;
    /* padding-top: 55px; */
}

/* Used to set placeholder text color for contact form's input fields (except textarea, handled below) */

input.form-control::placeholder {
    color: #CCC;
    opacity: 1;
}

/* Used to set placeholder text color for contact form's textarea; method above didn't work for this field */

#message::placeholder {
    color: #CCC;
    opacity: 1;
}

.navLinkText {
    font-family: Avenir;
    font-size: 14px;
}

.landingPageButton {
    /* color: gray; */
    /* color: #ff0000;   */
    background-color: rgba(148, 159, 182, 0.2);
    text-align: center;
    padding: 4px 8px 4px 8px;
    border: none;
    border-radius: 2px;
    transition-duration: 0.4s;
    font-family: Avenir;
    font-weight: 300;
}

.landingPageButton:hover {
    background-color: rgba(148, 159, 182, 0.5);
    color: gray;
}

.landingPageCustomText {
    font-family: Avenir;
}

.navbarHeaderText {
    /* font-size: 40pt; */
    font-weight: 300;
}

.navbarSubtitleText {
    /* font-size: 16pt; */
    font-weight: 200;
}

/* Photo Grid */

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

/* Create four equal columns that sits next to each other */

.column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */

@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }
}

/* NEW (testing) */

@media (max-width: 350px) {
    .navbarHeaderText {
        font-size: 9vw;
    }
    .navbarSubtitleText {
        font-size: 3.05vw;
    }
}

@media (min-width: 351px) and (max-width: 449px) {
    .navbarHeaderText {
        font-size: 10vw;
    }
    .navbarSubtitleText {
        font-size: 3.5vw;
    }
}

@media (min-width: 450px) and (max-width: 546px) {
    .navbarHeaderText {
        font-size: 10vw;
    }
    .navbarSubtitleText {
        font-size: 3.6vw;
    }
}

@media (min-width: 547px) and (max-width: 600px) {
    .navbarHeaderText {
        font-size: 10vw;
    }
    .navbarSubtitleText {
        font-size: 3.65vw;
    }
}

@media (min-width: 601px) and (max-width: 674px) {
    .navbarHeaderText {
        font-size: 6vw;
    }
    .navbarSubtitleText {
        font-size: 2.1vw;
    }
}

@media (min-width: 675px) and (max-width: 774px) {
    .navbarHeaderText {
        font-size: 6vw;
    }
    .navbarSubtitleText {
        font-size: 2.13vw;
    }
}

@media (min-width: 775px) and (max-width: 909px) {
    .navbarHeaderText {
        font-size: 6vw;
    }
    .navbarSubtitleText {
        font-size: 2.165vw;
    }
}

@media (min-width: 910px) and (max-width: 991px) {
    .navbarHeaderText {
        font-size: 6vw;
    }
    .navbarSubtitleText {
        font-size: 2.2vw;
    }
}

@media (min-width: 992px) and (max-width: 1370px) {
    .navbarHeaderText {
        font-size: 4vw;
    }
    .navbarSubtitleText {
        font-size: 1.435vw;
    }
}

@media (min-width: 1371px) and (max-width: 2000px) {
    .navbarHeaderText {
        font-size: 4vw;
    }
    .navbarSubtitleText {
        font-size: 1.47vw;
    }
}

@media (min-width: 2001px) {
    .navbarHeaderText {
        font-size: 4vw;
    }
    .navbarSubtitleText {
        font-size: 1.44vw;
    }
}

/* if (window.innerWidth <= 350) {
    navbarHeaderTextFontSize = '9vw'
    navbarSubtitleTextFontSize = '2.98vw'
  }

  if ((window.innerWidth > 350) && (window.innerWidth <= 600)) {
    navbarHeaderTextFontSize = '10vw'
    navbarSubtitleTextFontSize = '3.53vw'
  }

  if ((window.innerWidth > 600) && (window.innerWidth < 1000)) {
    navbarHeaderTextFontSize = '6vw'
    navbarSubtitleTextFontSize = '2.15vw'
  }

  if (window.innerWidth > 1000) {
    navbarHeaderTextFontSize = '4vw'
    navbarSubtitleTextFontSize = '1.49vw'
  } */

/* OLD (working) */

/* .navbarHeaderText {
    font-size: 40pt;
    font-weight: 300;
}

.navbarSubtitleText {
    font-size: 16pt;
    font-weight: 200;
} */

/* New carousel CSS */

.carousel {
    width: auto;
    height: auto;
}

.carousel-item>img {
    width: 100%;
    display: block;
    /* height: auto;     */
}

/* Carousel (working for non-reactstrap carousel) */

.carousel-control-prev-icon {
    filter: invert(1);
    width: 30px;
    height: 30px;
}

.carousel-control-next-icon {
    filter: invert(1);
    width: 30px;
    height: 30px;
}

.carousel-caption {
    filter: invert(1);
}

.carousel-indicators .active {
    background-color: #999999;
}

.carousel-indicators li {
    background-color: #CCCCCC;
    top: 20px;
}

/* end carousel */

/* OLD (working, except for iPhone SE-sized screens)) */

/* @media (max-width: 400px) {
    .navbarHeaderText {
        font-size: 19pt;
    }
    .navbarSubtitleText {
        font-size: 16pt;
        font-weight: 200;
    }
}

@media (max-width: 768px) {
    .navbar-toggler {
        margin-top: -10px;
    }
    .navbarHeaderText {
        font-size: 28pt;
    }
    .navbarSubtitleText {
        font-size: 16pt;
        font-weight: 200;
    }
} */

/* body {
    overflow-x: hidden;
    padding-top: 55px;
} */

/* @media (min-width: 200px) {
    div.test > img{ height: 300px !important ;}
  }
  
  @media (min-width: 300px) {
    div.test > img{ height: 300px !important; }
  }
  
  @media (min-width: 400px) {
    div.test > img{ height: 300px !important; }
  }
  @media (min-width: 600px) {
    div.test > img{ height: 400px !important ;}
  }
  @media (min-width: 800px) {
    div.test > img{ height: 600px !important ; }
  } */

/* @media (min-width:34em) {
      .card-deck > .card
      {
          width: 45%;
          flex-wrap: wrap;
          flex: initial; 
      }
  } */

/* @media screen and (max-width: 531px) {
      .container { display: flex; flex-flow: column; }
      .one { order: 2; }
      .two { order: 1;  }      
  }
  
  @media screen and (min-width: 600px)  {
          .mobile-break { display: none; }        
      }
      
      .navbar-custom {
        background-color: #FFFFFF
      }
      
      .navbar a{    
      font-family: Avenir;
      font-size: 14px;
      }
      
      
  @media (max-width: 768px) {
  
  
      .navbar-toggler {
          margin-top: -10px;
      }
    
      h1.navbarHeaderText {
          font-size: 24pt;
      }
  
  } */

/* @media (min-width: 769px) {  
    
      div.imageContainer > img {
          max-width: 60%;
      }  
      
      .mobile-break {
          display: none;
      }
  }
  
  small.footerText {
      font-family: Avenir;
  }
  
  @media (min-width: 576px) {
      body {
      padding-top: 25px;  
   }
    
      .navbar {
      margin-bottom: 25px;
      }
  } */

@media (max-width: 576px) {
    img.floatLeft {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    img.floatRight {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
}

@media (min-width: 577px) {
    img.floatLeft {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    img.floatRight {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
}

@media (min-width: 769px) {
    img.floatLeft {
        max-width: 100%;
        height: auto;
        margin-bottom: 22px;
    }
    img.floatRight {
        max-width: 100%;
        height: auto;
        margin-bottom: 22px;
    }
}

@media (min-width: 992px) {
    img.floatLeft {
        width: 40%;
        margin-right: 30px;
        margin-bottom: 15px;
    }
    img.floatRight {
        width: 40%;
        margin-left: 30px;
        margin-bottom: 15;
    }
}

@media (min-width: 1201px) {
    img.floatLeft {
        width: 40%;
        margin-right: 30px;
        margin-bottom: 15;
    }
    img.floatRight {
        width: 40%;
        margin-left: 30px;
        margin-bottom: 15;
    }
}

@media (min-width: 1401px) {
    img.floatLeft {
        width: 40%;
        margin-right: 30px;
        margin-bottom: 15;
    }
    img.floatRight {
        width: 40%;
        margin-left: 30px;
        margin-bottom: 15;
    }
}

p.customText {
    font-family: Avenir;
}

div.mediaBox {
    width: 95%;
    padding-left: 8px;
    padding-right: 8px;
    border-style: solid;
    border-width: thin;
    border-color: #000000;
    border-radius: 5px;
}

/* @media (min-width: 768) {
    div.mediaBox {
        border-style: hidden;
        width: 95%;
        padding-left: 8px;
        padding-right: 8px;
        border-width: thin;
        border-color: #000000;
        border-radius: 5px;
    }
}

@media (max-width: 767) {
    div.mediaBox {
        border-style: solid;
        width: 95%;
        padding-left: 8px;
        padding-right: 8px;
        border-width: thin;
        border-color: #000000;
        border-radius: 5px;
    }
} */

/* 
  
  .figure {
      display: table;
      margin-right: auto;
      margin-left: auto;
  }
  
  .figure-caption {
       display: table-caption;
      caption-side: bottom;
      text-align: right;
  }
  
  div.mediaBox {
      width: 95%;      
      padding-left: 8px;
      padding-right: 8px;
      border-style: solid;
      border-width: thin;
      border-color: #000000;
      border-radius: 5px;
  }
  
  @media (min-width: 768px) {
      div.mediaBox {
          border-style: hidden;
      }
  }
    
  @media (max-width: 400px) {
      h1.navbarHeaderText {
          font-size: 19pt;
      }
  }
  
  video.customPlayer {
      border-style: hidden;
      border-width: thin;
      border-color: #000000;
      border-radius: 5px;
  }
  
  audio.customPlayer {
      border-style: hidden;
      border-width: thin;
      border-color: #000000;
      border-radius: 5px;
  }
  
  @media (min-width: 300px) {
      iframe.album {
          height: 500px;
      }
  }
    
  @media (min-width: 350px) {
      iframe.album {
          height: 550px;
      }
  }
   
   
  @media (min-width: 400px) {
      iframe.album {
          height: 600px;
      }
  }
    
  @media (min-width: 450px) {
      iframe.album {
          height: 630px;
      }
  }
  
  @media (min-width: 500px) {
      iframe.album {
          height: 665px;
      }
  }   
    
  @media (min-width: 576px) {
      iframe.album {
          height: 675px;
      }
  }
    
  @media (min-width: 768px) {
      iframe.album {
          height: 475px;
      }
  }
    
  @media (min-width: 992px) {
      iframe.album {
          height: 575px;
      }
  }
    
  @media (min-width: 1201px) {
      iframe.album {
          height: 675px;
      }
  }
    
  @media (min-width: 1401px) {
      iframe.album {
          height: 675px;
      }
  }
  
  body, .form-control {
      font-family: Avenir;
  }
  
  
  label {
      color: #333;
  }
  .btn-send {
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
  }
  
  .help-block.with-errors {
      color: #ff5050;
      margin-top: 5px;
  }
  
  @media (min-width: 1200px) {
      img.custom {
          height: 225px;  
      }
  } */

  